.button {
    background: #44C960 0% 0% no-repeat padding-box;
    border-radius: 40px;
    text-align: center;
    font-size: 19px;
    letter-spacing: 0.38px;
    color: #FFFFFF;
    width:100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    padding: 0 0.5rem;
}