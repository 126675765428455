.header-container {
    height: 100px;
    background: #175BCA 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tagline{
    text-align: center;
    font-size:32px;
    letter-spacing: 0px;
    color: #FFFFFF;
}