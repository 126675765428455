.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.heading{
    text-align: center;
    font-size: 35px;
    color: #000000;
    margin-top: 100px;
    font-weight: 600;
    direction: rtl;
}
.subHeading{
    text-align: center;
    margin-top: 10px;
    font-size: 19px;
    color: #000000;
    font-weight: 600;
}

.install-content {
    margin: 55px auto;
    width: 60%;
    display: flex;
    direction: rtl;
    text-align: right;
    flex-direction: column;    
}

.text-container {
    padding-bottom: 10px;
}

.image-container {
    text-align: center;
    border: #96b7ec solid 1px;
}

.message-container {
    width: 448px;
    text-align: right;
    border: #96b7ec solid 1px;
}

.install-img {
    width: 1026px;
    height: 155px;
}

.morepad {
    padding-top: 20px;
}

.success-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tick{
    color:#44C960;
    font-size:30px;
}
.cross{
    color:red;
    font-size:30px;
}
.success{
    height: 75px;
    width:75px;
    border-radius: 50%;
    border:3px solid #44C960;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}


.success-button{
    margin-top: 70px;
    width:25%
}
@media screen and (max-width:767px) {
    .input-and-submit{
        width:60%;
    }
    .success-button{
        width:60%;
    }
}
.error{
    color:red;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    flex-direction: row-reverse;
}
