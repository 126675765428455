.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.heading{
    text-align: center;
    font-size: 35px;
    color: #000000;
    margin-top: 100px;
    font-weight: 600;
}
.subHeading{
    text-align: center;
    margin-top: 10px;
    font-size: 19px;
    color: #000000;
    font-weight: 600;
}

.input-and-submit{
    width:25%;
}

.controls {
    display: flex;
    flex-direction: column;
    direction: rtl;
}

.label {
    padding-right: 4px;
    font-weight: 500;
}

.radio-container {
    padding-top: 10px;
    display: flex;
}

.alert {
    padding: 8px 14px 8px 35px;
    margin-top: 10px;
    text-shadow: 0 1px 0 rgba(255,255,255,0.5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;    
    border-radius: 4px;
}

.alert-error {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}

.dates {
    height: 30px;
    width: 70px;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 30px;
    display: inline-block;   
    padding: 4px 6px;
    margin-bottom: 10px;
    font-size: 14px;    
    color: #555;
    vertical-align: middle;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.select-year {
    margin-left: 2px;
}

.select-month {
    margin-left: 2px;
}

@media screen and (max-width:767px) {
    .input-and-submit{
        width:60%;
    }
    
}